import { useWeb3State } from 'store';

import { chainIdToNetworkMap, networkConfigsMap, ORIGIN_NETWORK_NAME } from 'constants/config';

function useNetworkConfig () {
  const { provider } = useWeb3State();
  const networkName = (provider?.chainId && chainIdToNetworkMap?.[provider?.chainId]) || ORIGIN_NETWORK_NAME;

  return networkConfigsMap[networkName || ORIGIN_NETWORK_NAME];
}

export default useNetworkConfig;
