import type { RawProvider } from '@distributedlab/w3p';
import type { Provider } from '@ethersproject/providers';
import { QRC20__factory as Erc20 } from '@q-dev/gdk-sdk/lib/ethers-contracts/factories/QRC20__factory';
import { QRC721__factory as Erc721 } from '@q-dev/gdk-sdk/lib/ethers-contracts/factories/QRC721__factory';
import { providers, Signer } from 'ethers';

import { ERC_721_INTERFACE_UPGRADEABLE_ID } from 'constants/boundaries';

type AbstractFactoryClass = {
  connect: (address: string, signerOrProvider: Signer | Provider) => unknown;
  createInterface: () => unknown;
};

type AbstractFactoryClassReturnType<F extends AbstractFactoryClass> = {
  contractInstance: ReturnType<F['connect']>;
  contractInterface: ReturnType<F['createInterface']>;
};

function getFallbackOrInjectedProvider (rawProvider: RawProvider): providers.JsonRpcProvider | providers.Web3Provider {
  const isFallback = rawProvider instanceof providers.JsonRpcProvider;

  return isFallback
    ? rawProvider as providers.JsonRpcProvider
    : new providers.Web3Provider(rawProvider as providers.ExternalProvider, 'any');
}

const createContract = <F extends AbstractFactoryClass>(
  address: string,
  rawProvider: RawProvider,
  factoryClass: F,
): AbstractFactoryClassReturnType<F> => {
  const providerOrSigner = getFallbackOrInjectedProvider(rawProvider);

  const contractInstance = factoryClass.connect(
    address,
    providerOrSigner,
  ) as ReturnType<F['connect']>;

  const contractInterface = factoryClass.createInterface() as ReturnType<
  F['createInterface']
  >;

  return {
    contractInstance,
    contractInterface,
  };
};

export const createErc20Contract = (address: string, provider: RawProvider) => {
  const { contractInstance, contractInterface } = createContract(address, provider, Erc20);

  return {
    contractInstance,
    contractInterface,

    loadDetails: async () => {
      const [
        decimals,
        name,
        owner,
        symbol,
        totalSupply,
        totalSupplyCap
      ] =
        await Promise.all([
          contractInstance.decimals(),
          contractInstance.name(),
          contractInstance.owner(),
          contractInstance.symbol(),
          contractInstance.totalSupply(),
          contractInstance.totalSupplyCap()
        ]);
      return {
        decimals: decimals || 0,
        name: name || '',
        owner: owner || '',
        symbol: symbol || '',
        totalSupply: totalSupply.toString() || '',
        totalSupplyCap: totalSupplyCap.toString() || '',
      };
    }
  };
};

export const createErc721Contract = (address: string, rawProvider: RawProvider) => {
  const { contractInstance, contractInterface } = createContract(address, rawProvider, Erc721);

  return {
    contractInstance,
    contractInterface,

    loadDetails: async () => {
      const [
        name,
        owner,
        symbol,
        totalSupply,
        totalSupplyCap,
        baseUri
      ] =
        await Promise.all([
          contractInstance.name(),
          contractInstance.owner(),
          contractInstance.symbol(),
          contractInstance.totalSupply(),
          contractInstance.totalSupplyCap(),
          contractInstance.baseURI()
        ]);

      return {
        decimals: 0,
        name,
        owner,
        symbol,
        totalSupply: totalSupply.toString(),
        totalSupplyCap: totalSupplyCap.toString(),
        baseURI: baseUri,
      };
    },

    isUpgradable: async () => {
      return await contractInstance.supportsInterface(ERC_721_INTERFACE_UPGRADEABLE_ID);
    },

    safeCheckIsUpgradable: async () => {
      try {
        return await contractInstance.supportsInterface(ERC_721_INTERFACE_UPGRADEABLE_ID);
      } catch (error) {
        return false;
      }
    }
  };
};
