
export const DAO_PURPOSE_START_TEXT = 'The purpose of the DAO is ';
export const DEFAULT_GUARDIANS_PANEL_NAME = 'Guardians';
export const DEFAULT_GUARDIANS_PANEL_PURPOSE = [
  'monitor proposals brought forth in the DAO and cast a veto if necessary for the protection of the DAO'
];

export const PURPOSE_PARTS = 'abcdefghijklmnopqrstuvwxyz'
  .split('')
  .map((letter) => `(${letter})`);
;

export const EMPTY_CONSTITUTION_LAW = 'NONE';
export const CONSTITUTION_LAWS = [
  'Swiss law',
  EMPTY_CONSTITUTION_LAW,
  'Afghan law',
  'Albanian law',
  'Algerian law',
  'American law',
  'Andorran law',
  'Angolan law',
  'Antiguan and Barbudan law',
  'Argentine law',
  'Armenian law',
  'Australian law',
  'Austrian law',
  'Azerbaijani law',
  'Bahamian law',
  'Bahraini law',
  'Bangladeshi law',
  'Barbadian law',
  'Belarusian law',
  'Belgian law',
  'Belizean law',
  'Beninese law',
  'Bhutanese law',
  'Bolivian law',
  'Bosnian and Herzegovinian law',
  'Botswanan law',
  'Brazilian law',
  'British law',
  'Bruneian law',
  'Bulgarian law',
  'Burkinabe law',
  'Burmese law',
  'Burundian law',
  'Cambodian law',
  'Cameroonian law',
  'Canadian law',
  'Cape Verdean law',
  'Central African law',
  'Chilean law',
  'Chinese law',
  'Colombian law',
  'Comorian law',
  'Congolese law',
  'Cook Islands law',
  'Costa Rican law',
  'Croatian law',
  'Cuban law',
  'Cypriot law',
  'Czech law',
  'Danish law',
  'Djiboutian law',
  'Dominican law',
  'Dutch law',
  'Ecuadorian law',
  'Egyptian law',
  'Emirati law',
  'Estonian law',
  'Ethiopian law',
  'Fijian law',
  'Finnish law',
  'French law',
  'Gabonese law',
  'Georgian law',
  'German law',
  'Ghanaian law',
  'Greek law',
  'Guatemalan law',
  'Guinean law',
  'Guyanese law',
  'Haitian law',
  'Honduran law',
  'Hungarian law',
  'Icelandic law',
  'Indian law',
  'Indonesian law',
  'Iranian law',
  'Iraqi law',
  'Irish law',
  'Israeli law',
  'Italian law',
  'Ivorian law',
  'Jamaican law',
  'Japanese law',
  'Jordanian law',
  'Kazakhstani law',
  'Kenyan law',
  'Kuwaiti law',
  'Kyrgyz law',
  'Laotian law',
  'Latvian law',
  'Lebanese law',
  'Lesotho law',
  'Liberian law',
  'Liechtenstein law',
  'Lithuanian law',
  'Luxembourgish law',
  'Macedonian law',
  'Malagasy law',
  'Malawian law',
  'Malaysian law',
  'Maldivian law',
  'Malian law',
  'Maltese law',
  'Marshallese law',
  'Mauritanian law',
  'Mauritian law',
  'Mexican law',
  'Moldovan law',
  'Monegasque law',
  'Mongolian law',
  'Montenegrin law',
  'Moroccan law',
  'Mozambican law',
  'Nepalese law',
  'New Zealand law',
  'Nicaraguan law',
  'Nigerian law',
  'Nigerien law',
  'Norwegian law',
  'Omani law',
  'Pakistani law',
  'Palauan law',
  'Palestinian law',
  'Panamanian law',
  'Papua New Guinean law',
  'Paraguayan law',
  'Peruvian law',
  'Philippine law',
  'Polish law',
  'Portuguese law',
  'Qatari law',
  'Romanian law',
  'Russian law',
  'Rwandan law',
  'Salvadoran law',
  'Sammarinese law',
  'Santomean law',
  'Saudi Arabian law',
  'Senegalese law',
  'Serbian law',
  'Seychellois law',
  'Sierra Leonean law',
  'Singaporean law',
  'Slovak law',
  'Slovenian law',
  'South African law',
  'South Korean law',
  'Spanish law',
  'Sri Lankan law',
  'Sudanese law',
  'Surinamese law',
  'Swedish law',
  'Syrian law',
  'Tajik law',
  'Tanzanian law',
  'Thai law',
  'Timorese law',
  'Tongan law',
  'Trinidadian and Tobagonian law',
  'Tunisian law',
  'Turkish law',
  'Turkmen law',
  'Ugandan law',
  'Ukrainian law',
  'Uruguayan law',
  'Uzbek law',
  'Vatican law',
  'Venezuelan law',
  'Vietnamese law',
  'Vincentian law',
  'Zambian law',
  'Zimbabwean law'
];

export const ARBITRATION_SEATS = [
  'Zurich, Switzerland',
  'Paris, France',
  'London, UK',
  'New York, USA',
  'Singapore, Singapore',
];
