import { ReactNode, useState } from 'react';

import Header from 'navigation/Header/Header';
import Sidebar from 'navigation/Sidebar';

import { AppContainer } from './styles';

interface Props {
  children: ReactNode;
}

function Layout ({ children }: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <AppContainer>
        <Sidebar
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
        />
        <div className="app__content">
          <Header onMenuClick={() => setSidebarOpen(true)} />
          <main className="app__main">
            <div className="app__main-content">{children}</div>
          </main>
        </div>
      </AppContainer>
    </>
  );
}

export default Layout;
