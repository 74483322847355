import { Chain, CHAIN_TYPES } from '@distributedlab/w3p';
import { utils } from 'ethers';

import {
  FIFTY_PERCENTAGE,
  FIVE_PERCENTAGE,
  THIRTY_PERCENTAGE,
  TWO_PERCENTAGE,
} from 'constants/contracts';

export type NetworkName = 'mainnet' | 'testnet' | 'devnet';

export interface NetworkConfig {
  chainId: number;
  name: string;
  networkName: NetworkName;
  dAppUrl: string;
  daoAppUrl: string;
  rpcUrl: string;
  indexerUrl: string;
  explorerUrl: string;
  gnosisSafeUrl: string;
  qBridgeUrl: string;
  docsUrl: string;
  constitutionUrl: string;
  gasBuffer: number;
  featureFlags: {
    aliases: boolean;
  };
  masterDaoFactory: string;
  masterContractRegistry: string;
  extendedDaoVoting: string;
  generalExtendedDaoVoting: string;
  dashboardBaseUrl: string;
  faucetUrl: string;
  defaultVotingParams: {
    votingPeriod: number;
    appealPeriod: number;
    vetoPeriod: number;
    proposalExecutionPeriod: number;
    requiredQuorum: string;
    requiredMajority: string;
    requiredVetoQuorum: string;
    votingType: number;
    votingMinAmount: string;
  };
  qRootNodesVetoParams: {
    contractAddress: string;
    vetoPeriod: number;
    requiredVetoQuorum: string;
  };
  defaultModules: {
    treasury: string;
    nftMinting: string;
    proxyUpgrader: string;
  };
}

export type GsnConfig = {
  relays: string[];
  paymaster: string;
};

export const networkConfigsMap: Record<NetworkName, NetworkConfig> = {
  mainnet: {
    chainId: 35441,
    name: 'Q Mainnet',
    networkName: 'mainnet',
    dAppUrl: 'https://hq.q.org',
    daoAppUrl: 'https://factory.q-dao.tools',
    rpcUrl: 'https://rpc.q.org',
    indexerUrl: 'https://indexer.q.org',
    explorerUrl: 'https://explorer.q.org',
    gnosisSafeUrl: 'https://multisig-ui.q.org',
    qBridgeUrl: 'https://bridge.q.org',
    docsUrl: 'https://docs.q-dao.tools',
    constitutionUrl: 'https://constitution.q.org',
    gasBuffer: 1.5,
    featureFlags: { aliases: true },
    masterDaoFactory: '0x32eAb152703C28a96cd889B1034BfA0A6A70bce4',
    masterContractRegistry: '0x4c9E11f981024f5c982294564C0927032352dE1a',
    extendedDaoVoting: '0xA44066A8Dd8D667E98Fd628bBf8D28289C758D33',
    generalExtendedDaoVoting: '0x8695C918D2e854d9F640324D1Aad0FD9CF527E5f',
    dashboardBaseUrl: 'http://hq.q-dao.tools',
    faucetUrl: 'https://faucet.q.org',
    defaultVotingParams: {
      votingPeriod: 3 * 24 * 60 * 60, // 3 days
      appealPeriod: 24 * 60 * 60, // 1 day
      vetoPeriod: 24 * 60 * 60, // 1 day
      proposalExecutionPeriod: 21 * 24 * 60 * 60, // 3 weeks
      requiredQuorum: TWO_PERCENTAGE,
      requiredMajority: FIFTY_PERCENTAGE,
      requiredVetoQuorum: THIRTY_PERCENTAGE,
      votingType: 0,
      votingMinAmount: '1',
    },
    qRootNodesVetoParams: {
      contractAddress: '0x536061A4A6633d5A1AF99DE29B7cE82439e1e5c0',
      vetoPeriod: 14 * 24 * 60 * 60, // 14 day
      requiredVetoQuorum: FIFTY_PERCENTAGE,
    },
    defaultModules: {
      treasury: '0x7A0CB9F3944aB09Ee9da8EB27f4044df4161F1ab',
      nftMinting: '0xc6E9F942fA51921e21eaE4DcF859944719D90A63',
      proxyUpgrader: '0x86Fa17473F8259745CdcF6EEc56abB9D52E912f0',
    }
  },
  testnet: {
    chainId: 35443,
    name: 'Q Testnet',
    networkName: 'testnet',
    dAppUrl: 'https://hq.qtestnet.org',
    daoAppUrl: 'https://factory.q-dao.tools',
    rpcUrl: 'https://rpc.qtestnet.org',
    indexerUrl: 'https://indexer.qtestnet.org',
    explorerUrl: 'https://explorer.qtestnet.org',
    gnosisSafeUrl: 'https://multisig.qtestnet.org',
    qBridgeUrl: 'https://bridge.qtestnet.org',
    docsUrl: 'https://docs.q-dao.tools',
    constitutionUrl: 'https://constitution.qtestnet.org',
    gasBuffer: 2,
    featureFlags: { aliases: true },
    masterDaoFactory: '0xbD7a751cf4752d926959563468769a348f3fA21E',
    masterContractRegistry: '0xce7c8cbAA0a4ADadE7Fd2C4Eb9323c8c937402dc',
    extendedDaoVoting: '0x6A5f72D83dc284e916CE92977f2846871E9F3D92',
    generalExtendedDaoVoting: '0x4Bb1a6f189F4Ecb5d1680f87487d83Edd573c346',
    dashboardBaseUrl: 'http://hq.q-dao.tools',
    faucetUrl: 'https://faucet.qtestnet.org',
    defaultVotingParams: {
      votingPeriod: 5 * 60, // 5 minutes
      appealPeriod: 24 * 60 * 60, // 1 day
      vetoPeriod: 5 * 60, // 5 minutes
      proposalExecutionPeriod: 14 * 24 * 60 * 60, // 2 weeks
      requiredQuorum: FIVE_PERCENTAGE,
      requiredMajority: FIFTY_PERCENTAGE,
      requiredVetoQuorum: FIFTY_PERCENTAGE,
      votingType: 0,
      votingMinAmount: '1',
    },
    qRootNodesVetoParams: {
      contractAddress: '0x9b1Ab43e9d948358c93C6B89BEBD84434f112Af5',
      vetoPeriod: 5 * 60, // 5 minutes
      requiredVetoQuorum: FIFTY_PERCENTAGE,
    },
    defaultModules: {
      treasury: '0xD98Cd353f5274137d926419A9A505623AAe22CA9',
      nftMinting: '0x5d897fF501a9d322ae6c0d37a6b267B7bdaF719f',
      proxyUpgrader: '0x5b1F2Fc4C1fA4a3a09d8562B1941E4ad72262ce7',
    }
  },
  devnet: {
    chainId: 35442,
    name: 'Q Devnet',
    networkName: 'devnet',
    dAppUrl: 'https://hq.qdevnet.org',
    daoAppUrl: 'https://dao-factory.qdevnet.org',
    rpcUrl: 'https://rpc.qdevnet.org',
    indexerUrl: 'https://indexer.qdevnet.org',
    explorerUrl: 'https://explorer.qdevnet.org',
    gnosisSafeUrl: 'https://multisig.qdevnet.org',
    qBridgeUrl: 'https://bridge.qdevnet.org',
    docsUrl: 'https://docs.q-dao.tools',
    constitutionUrl: 'https://constitution.qdevnet.org',
    gasBuffer: 1.5,
    featureFlags: { aliases: true },
    masterDaoFactory: '0xD93f3Ffc353A93FF46bb64afea7e99630E493A11',
    masterContractRegistry: '0x80e4103077Df86a939C7dD3A6f10d5A9e6e8a8d1',
    extendedDaoVoting: '0xE66a863349076cA286D87C504DA662C8d5bAeb58',
    generalExtendedDaoVoting: '0x730925F7528330b0AD79c0168b768a3f145b36d7',
    dashboardBaseUrl: 'http://dao-hq.qdevnet.org',
    faucetUrl: 'https://faucet.qdevnet.org',
    defaultVotingParams: {
      votingPeriod: 5 * 60, // 5 minutes
      appealPeriod: 24 * 60 * 60, // 1 day
      vetoPeriod: 5 * 60, // 5 minutes
      proposalExecutionPeriod: 14 * 24 * 60 * 60, // 2 weeks
      requiredQuorum: FIVE_PERCENTAGE,
      requiredMajority: FIFTY_PERCENTAGE,
      requiredVetoQuorum: FIFTY_PERCENTAGE,
      votingType: 0,
      votingMinAmount: '1',
    },
    qRootNodesVetoParams: {
      contractAddress: '0x536061A4A6633d5A1AF99DE29B7cE82439e1e5c0',
      vetoPeriod: 5 * 60, // 5 minutes
      requiredVetoQuorum: FIFTY_PERCENTAGE,
    },
    defaultModules: {
      treasury: '0x6869f1B7Ba059947Ab07C1b2B94b52099cfe4B96',
      nftMinting: '0xAD92dbbe4709C64EBF9fB0b62720E15A33A2e426',
      proxyUpgrader: '0xb4DEB115D2ca461405e865655E4DBF4F61A16325',
    }
  },
};

export const chainIdToNetworkMap: { [key: string]: NetworkName } = {
  35441: 'mainnet',
  35442: 'devnet',
  35443: 'testnet',
};

export const connectorParametersMap = Object.values(networkConfigsMap)
  .reduce((acc, config) => {
    acc[config.chainId] = {
      id: utils.hexlify(config.chainId),
      name: config.name,
      rpcUrl: config.rpcUrl,
      explorerUrl: config.explorerUrl,
      token: {
        name: 'Q',
        // HACK: MetaMask requires the symbol to have at least 2 characters
        symbol: 'Q ',
        decimals: 18,
      },
      type: CHAIN_TYPES.EVM,
      icon: ''
    };
    return acc;
  }, {} as { [key: string]: Chain });

const originToNetworkMap: { [key: string]: NetworkName } = {
  'https://factory.q-dao.tools': 'mainnet',
  'https://dao-factory.qdevnet.org': 'testnet',
  'http://localhost:3000': 'devnet',
};

export const gsnConfigMap: Record<NetworkName, GsnConfig> = {
  mainnet: {
    relays: ['https://gsn.q.org'],
    paymaster: '0x045EA50dA6730b2377794977dAdF582580c30A6F',
  },
  testnet: {
    relays: ['https://gsn.qtestnet.org'],
    paymaster: '0xd7Da31BE8647487eA2E0C5163C3cfd8e82627e43',
  },
  devnet: {
    relays: ['https://gsn.qdevnet.org'],
    paymaster: '0x54C002445C30f0c951A6eFFff04e8D3eB27e2d9c',
  }
};

export const moduleExternalLinks = {
  treasury: 'https://ipfs.io/ipfs/QmQ3WDEPkPgaMU5gg69Bq2tN4NUamL82LeG921txT4zQxf',
  nftMinting: 'https://ipfs.io/ipfs/QmRCTkf8rCdGygjS6zhBFVvVwUDJdx3PchWNGfqjDo1ESi',
  proxyUpgrader: 'https://ipfs.io/ipfs/QmX9jAHP8Df5jHMaDre6Lf2WUSm8qdjeknyQuZCtT473sH',
};

export const ORIGIN_NETWORK_NAME: NetworkName = originToNetworkMap[window.location.origin] || 'testnet';
