import { ContractTransaction } from 'ethers';
import { createStore, ErrorHandler } from 'helpers';
import { t } from 'i18next';
import uniqueId from 'lodash/uniqueId';

export type TxStatus = 'pending' | 'sending' | 'success' | 'error';

export type Transaction = {
  id: string;
  message: string;
  status: TxStatus;
  hash?: string;
  isClosedModal?: boolean;
};

export type TransactionEditableParams = {
  title?: string;
  status?: TxStatus;
  isConfirmed?: boolean;
  isClosedModal?: boolean;
  hash?: string;
};

type TransactionState = {
  transactions: Transaction[];
};

export const [transactionsStore, useTransactionsState] = createStore(
  'transactions',
  {
    transactions: [],
  } as TransactionState,
  () => ({}),
  (state) => ({
    submitTransaction: async ({
      id = uniqueId(),
      submitFn,
      successMessage,
      onSuccess = () => {},
      onConfirm = () => {},
      onError = () => {},
    }: {
      submitFn: () => Promise<ContractTransaction | void>;
      successMessage?: string;
      id?: string;
      onSuccess?: () => void | Promise<void>;
      onConfirm?: () => void;
      onError?: (error?: unknown) => void;
    }) => {
      const transaction: Transaction = {
        id,
        message: successMessage || t('DEFAULT_MESSAGE_TX'),
        status: 'pending',
      };

      transactionsStore.setOrHardUpdateTx(transaction);

      try {
        const submitResponse = await submitFn();

        if (submitResponse?.wait) {
          transactionsStore.updateTransaction(transaction.id, { hash: submitResponse.hash, status: 'sending' });
          onConfirm();
          await submitResponse.wait();
        }

        transactionsStore.updateTransaction(transaction.id, { status: 'success' });

        await onSuccess();
      } catch (error) {
        ErrorHandler.process(error);
        transactionsStore.updateTransaction(transaction.id, { status: 'error' });
        onError(error);
      }
    },

    setOrHardUpdateTx: (tx: Transaction) => {
      const txIndex = state.transactions.findIndex(({ id }) => id === tx.id);

      if (txIndex === -1) {
        state.transactions = [{ ...tx }, ...state.transactions];
        return;
      };

      const newTxs = [...state.transactions];
      newTxs[txIndex] = { ...tx };

      state.transactions = newTxs;
    },

    updateTransaction: (id: string, params: TransactionEditableParams) => {
      const txIndex = state.transactions.findIndex((tx: Transaction) => tx.id === id);

      if (txIndex === -1) return;

      const newTxs = [...state.transactions];
      newTxs[txIndex] = { ...newTxs[txIndex], ...params };

      state.transactions = newTxs;
    },

    resetTransactionsStore: () => {
      state.transactions = [];
    },
  }),
  {
    isPersist: false,
  }
);
