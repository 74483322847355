import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { StyleProvider } from '@q-dev/q-ui-kit';
import LanguageProvider from 'context/LanguageProvider';

import Layout from 'components/Layout';
import NotificationManager from 'components/NotificationManager';
import Web3Initializer from 'components/Web3Initializer';
import Routes from 'navigation/Routes';

import '@mdi/font/css/materialdesignicons.min.css';

ReactDOM.render(
  <StyleProvider>
    <LanguageProvider>
      <Web3Initializer>
        <BrowserRouter>
          <Layout>
            <Routes />
          </Layout>
        </BrowserRouter>
      </Web3Initializer>
      <NotificationManager />
    </LanguageProvider>
  </StyleProvider>,
  document.getElementById('root')
);
