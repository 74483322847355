import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import LazyLoading from 'components/Base/LazyLoading';
import ErrorBoundary from 'components/Custom/ErrorBoundary';

import { RoutePaths } from 'constants/routes';

const DataPrivacy = lazy(() => import('pages/DataPrivacy'));
const Imprint = lazy(() => import('pages/Imprint'));
const NotFound = lazy(() => import('pages/NotFound'));
const DaoCreation = lazy(() => import('pages/DaoCreation'));

function Routes () {
  return (
    <ErrorBoundary>
      <LazyLoading>
        <Switch>
          <Route exact path={RoutePaths.main}>
            <DaoCreation />
          </Route>

          <Route exact path={RoutePaths.imprint}>
            <Imprint />
          </Route>
          <Route exact path={RoutePaths.privacy}>
            <DataPrivacy />
          </Route>

          <Route component={NotFound} />
        </Switch>
      </LazyLoading>
    </ErrorBoundary>
  );
}

export default Routes;
