import { PropsWithChildren, useEffect } from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import NetworkWarning from './NetworkWarning';

import { useWeb3State, web3Store } from 'store';

export const Wrap = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  color: ${({ theme }) => theme.colors.textPrimary};
  text-align: center;

  .breathing-q {
    width: 100px;
    height: 100px;
  }

  .breathing-q__logo {
    width: 100%;
    height: auto;
  }
`;

/**
 * Temporary component to initialize web3 provider
 * TODO: remove once all dao controllers will be fully moved to valtio store
 * @param children
 * @constructor
 */
export default function Web3Initializer ({ children }: PropsWithChildren) {
  const { provider, isRightNetwork } = useWeb3State();

  useEffect(() => {
    web3Store.init();
  }, []);

  if (!provider || !provider?.chainId) {
    return (
      <Wrap>
        <motion.div
          className="breathing-q"
          animate={{ scale: 1.2 }}
          transition={{
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeOut',
            duration: 0.5
          }}
        >
          <img
            className="breathing-q__logo"
            src="/logo.png"
            alt="q"
          />
        </motion.div>
      </Wrap>
    );
  }

  /**
   * is current chainId is supported by Q networks environment
   */

  if (provider?.isConnected && !isRightNetwork) {
    return (
      <NetworkWarning />
    );
  }

  return (
    <div key={[provider?.chainId, provider?.address].join('')}>{children}</div>
  );
}
