import {
  ChainId,
  CoinbaseProvider,
  createProvider, FallbackEvmProvider,
  MetamaskProvider,
  Provider,
  ProviderDetector,
  ProviderProxyConstructor,
  PROVIDERS, RawProvider,
} from '@distributedlab/w3p';
import { RelayProvider } from '@opengsn/provider';
import { errors } from 'errors';
import { providers, Signer } from 'ethers';
import { createStore, sleep } from 'helpers';
import { ref } from 'valtio';

import {
  chainIdToNetworkMap,
  connectorParametersMap,
  gsnConfigMap,
  networkConfigsMap,
  ORIGIN_NETWORK_NAME
} from 'constants/config';

export enum FALLBACK_PROVIDER_NAMES {
  mainnetFallback = 'mainnetfallback',
  testnetFallback = 'testnetfallback',
  devnetFallback = 'devnetfallback'
}

const FALLBACK_PROVIDERS_TYPES = {
  mainnet: FALLBACK_PROVIDER_NAMES.mainnetFallback,
  testnet: FALLBACK_PROVIDER_NAMES.testnetFallback,
  devnet: FALLBACK_PROVIDER_NAMES.devnetFallback,
};

const ORIGIN_PROVIDER_TYPE = FALLBACK_PROVIDERS_TYPES[ORIGIN_NETWORK_NAME];

export type SupportedProviders = PROVIDERS | FALLBACK_PROVIDER_NAMES;

type Web3Store = {
  provider: Provider;
  providerDetector: ProviderDetector<SupportedProviders> | undefined;
  providerType: SupportedProviders | undefined;
  providerChainId: ChainId | undefined;
  gsnSigner: Signer | undefined;
};

export class MainnetFallback extends FallbackEvmProvider {
  static get providerType (): string {
    return FALLBACK_PROVIDER_NAMES.mainnetFallback;
  }
}

export class TestnetFallback extends FallbackEvmProvider {
  static get providerType (): string {
    return FALLBACK_PROVIDER_NAMES.testnetFallback;
  }
}

export class DevnetFallback extends FallbackEvmProvider {
  static get providerType (): string {
    return FALLBACK_PROVIDER_NAMES.devnetFallback;
  }
}

const providerDetector = new ProviderDetector<SupportedProviders>();

Object.values({
  [FALLBACK_PROVIDER_NAMES.mainnetFallback]: {
    name: FALLBACK_PROVIDER_NAMES.mainnetFallback,
    instance: new providers.JsonRpcProvider(networkConfigsMap.mainnet.rpcUrl, 'any') as unknown as RawProvider,
  },
  [FALLBACK_PROVIDER_NAMES.testnetFallback]: {
    name: FALLBACK_PROVIDER_NAMES.testnetFallback,
    instance: new providers.JsonRpcProvider(networkConfigsMap.testnet.rpcUrl, 'any') as unknown as RawProvider,
  },
  [FALLBACK_PROVIDER_NAMES.devnetFallback]: {
    name: FALLBACK_PROVIDER_NAMES.devnetFallback,
    instance: new providers.JsonRpcProvider(networkConfigsMap.devnet.rpcUrl, 'any') as unknown as RawProvider,
  },
}).forEach((el) => {
  providerDetector.addProvider(el);
});

const PROVIDERS_PROXIES: { [key in SupportedProviders]?: ProviderProxyConstructor } = {
  [PROVIDERS.Metamask]: MetamaskProvider,
  [PROVIDERS.Coinbase]: CoinbaseProvider,
  [FALLBACK_PROVIDER_NAMES.mainnetFallback]: MainnetFallback,
  [FALLBACK_PROVIDER_NAMES.testnetFallback]: TestnetFallback,
  [FALLBACK_PROVIDER_NAMES.devnetFallback]: DevnetFallback,
};

Provider.setChainsDetails(connectorParametersMap);

export const [web3Store, useWeb3State] = createStore(
  'web3',
  {
    provider: {} as Provider,
    providerDetector: undefined,
    providerType: ORIGIN_PROVIDER_TYPE,
    providerChainId: undefined,
    gsnSigner: undefined,
  } as Web3Store,
  (state) => ({
    get isRightNetwork (): boolean {
      return Boolean(state.provider?.chainId && chainIdToNetworkMap[state.provider.chainId]);
    },

    get ethersFallbackOrInjectedProvider () {
      if (!state.provider.rawProvider) return undefined;

      const isFallback = state.provider.rawProvider instanceof providers.JsonRpcProvider;

      return isFallback
        ? state.provider.rawProvider as unknown as providers.JsonRpcProvider
        : new providers.Web3Provider(state.provider.rawProvider as providers.ExternalProvider, 'any');
    },
  }),
  (state) => ({
    init: async (_providerType?: SupportedProviders) => {
      let providerType = _providerType;

      if (!providerType) {
        providerType = state.providerType || ORIGIN_PROVIDER_TYPE!;
      }

      if (!(providerType in PROVIDERS_PROXIES)) throw new TypeError('Provider not supported');

      const providerProxy = PROVIDERS_PROXIES[providerType]!;

      state.provider?.clearHandlers?.();

      state.providerDetector = ref(providerDetector);

      /**
       * because of proxy aint works with private fields in objects, we should use `valtio ref`,
       * and to keep valtio proxy "rolling" - we should update state ref property,
       * e.g. onAccountChanged or onChainChanged, ...etc
       */
      const initiatedProvider = await createProvider(providerProxy, {
        providerDetector,
        listeners: {
          onChainChanged: (e) => {
            state.providerChainId = e?.chainId;
            web3Store.init(providerType);
          },
          onAccountChanged: (e) => {
            // HOTFIX: double check if user disconnected from wallet
            if (!e?.address) {
              web3Store.disconnect();

              return;
            }

            web3Store.init(providerType);
          },
          onDisconnect: () => {
            web3Store.disconnect();
          },
        },
      });

      state.provider = ref(initiatedProvider);

      state.providerType = providerType;
      state.providerChainId = initiatedProvider.chainId;

      if (
        initiatedProvider?.rawProvider &&
        initiatedProvider?.chainId &&
        initiatedProvider?.address
      ) {
        state.gsnSigner = await web3Store.getGsnSigner(initiatedProvider.rawProvider, initiatedProvider.chainId);
      }

      await state.provider.connect();

      // hotfix injected provider listeners updating provider proxy object
      await sleep(300);
    },

    safeSwitchNetwork: async (chainId: ChainId) => {
      if (!state.provider?.address) {
        await web3Store.init(FALLBACK_PROVIDERS_TYPES[chainIdToNetworkMap[chainId]]);

        return;
      }

      try {
        await web3Store.provider?.switchChain(chainId);
      } catch (error) {
        if (error instanceof errors.ProviderInternalError || error instanceof errors.ProviderChainNotFoundError) {
          const chainDetails = Provider.chainsDetails?.[chainId];

          if (!chainDetails) {
            throw error;
          }

          await web3Store.provider?.addChain(chainDetails);

          return;
        }

        throw error;
      }
    },

    disconnect: async () => {
      await state.provider?.disconnect();

      state.providerType = state.providerChainId
        ? FALLBACK_PROVIDERS_TYPES[chainIdToNetworkMap[state.providerChainId]]
        : ORIGIN_PROVIDER_TYPE;

      await web3Store.init();
    },

    getGsnSigner: async (rawProvider: RawProvider, chainId: ChainId): Promise<Signer | undefined> => {
      const gsnConfig = gsnConfigMap[chainIdToNetworkMap[Number(chainId)]];

      if (!gsnConfig) return undefined;

      const relayProvider = RelayProvider.newProvider({
        provider: rawProvider as unknown as RelayProvider,
        config: {
          loggerConfiguration: { logLevel: 'error' },
          paymasterAddress: gsnConfig.paymaster,
          maxRelayNonceGap: 10000,
          preferredRelays: gsnConfig.relays,
        },
      });

      await relayProvider.init();

      const gsnProvider = new providers.Web3Provider(
        relayProvider as unknown as providers.ExternalProvider,
      );

      return ref(gsnProvider.getSigner());
    },

    getNetworkConfig: () => {
      const networkName = state.provider?.chainId && chainIdToNetworkMap?.[state.provider?.chainId];

      return networkConfigsMap[networkName || ORIGIN_NETWORK_NAME];
    }
  }),
  {
    persistProperties: ['providerType', 'providerChainId']
  },
);
