import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@q-dev/q-ui-kit';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';

import { useWeb3State, web3Store } from 'store';

const WalletBaseInfoWrapper = styled.div`
  .wallet-base-info__address-button {
    padding: 12px 20px;
    background-color: transparent;
    border: none;
    width: 100%;
    display: flex;
    gap: 12px;
    white-space: nowrap;

    &:hover {
      background-color: ${({ theme }) => theme.colors.tertiaryLight};
    }
  }


  .wallet-base-info__address-button-icon {
    font-size: 20px;
  }
`;

function WalletBaseInfo () {
  const { t } = useTranslation();
  const { provider } = useWeb3State();
  const [isCopied, setIsCopied] = useState(false);

  function copyAddress () {
    if (!provider?.address) return;

    copy(provider?.address);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }

  return (
    <WalletBaseInfoWrapper>
      <button
        type="button"
        className="wallet-base-info__address-button text-lg color-primary"
        onClick={copyAddress}
      >
        <Icon name="copy" />
        <span>{isCopied ? t('COPIED') : t('COPY_ADDRESS')}</span>
      </button>

      <button
        type="button"
        className="wallet-base-info__address-button text-lg color-primary"
        onClick={() => web3Store.disconnect()}
      >
        <Icon name="sign-out" />
        <span>{t('DISCONNECT_WALLET')}</span>
      </button>
    </WalletBaseInfoWrapper>
  );
}

export default WalletBaseInfo;
