export enum TimeVariants {
  Seconds = 'Seconds',
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
}

export const TIME_MULTIPLIERS = {
  [TimeVariants.Seconds]: 1,
  [TimeVariants.Minutes]: 60,
  [TimeVariants.Hours]: 60 * 60,
  [TimeVariants.Days]: 60 * 60 * 24,
};

export type TimeFieldValue = {
  timeInSec: string;
  amount: string;
  time: TimeVariants;
};

export type TimeFieldOnChange = (value: TimeFieldValue) => void;
